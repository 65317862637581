<template>
  <div>
    <div class="w-full text-right mr-2 mb-4">

<!-- <router-link to="/dashboard" class="text-sm md:text-base text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
</div>

  <vx-card class="px-4 bg-h-primary-light">
    <div v-if="$route.query.type === '0'">
      <div class="flex w-full items-center">
        <div class="m-1 flex w-10 justify-center bg-h-secondary rounded-lg p-3">
          <b-icon icon="bell-fill" />
        </div>
        <h4 class="dana-font mt-3 ml-3 text-white">{{ NotifeData[0].title }}</h4>
      </div>
      <div v-html="NotifeData[0].description" style="color: white" class="mt-5 leading-6 text-base ">

      </div>
      <div class="text-right mt-5 border-5">
        <span>{{ NotifeData[0].created_at }}</span>
      </div>
    </div>
    <div v-if="$route.query.type === '1'">
      <div class="flex w-full items-center">
        <div class="m-1 flex w-10 justify-center blue-light p-3">
          <b-icon icon="bell-fill" />
        </div>
        <h4 class="dana-font mt-3 ml-3">{{ NotifeData.title }}</h4>
      </div>
      <div style="color: white" class="mt-5 leading-6 text-sm ">
        <b-container>
          <p class="leading-loose text-base">
            {{ NotifeData.text }}
          </p>
        </b-container>
      </div>
      <div v-if="NotifeData.link_to !== null && ''" class="flex mt-5 justify-content-end">
        <vs-button target="_blank" :href="NotifeData.link_to">مشاهده</vs-button>
      </div>
    </div>
    <!-- {{notifList}} -->
  </vx-card>
</div>
</template>

<script>
export default {
  name: 'ShowNotification',
  data () {
    return {
      NotifeData: []
    }
  },
  computed: {
    notifList () {
      return this.$store.getters['notif/getNotificationList']
    }
  },
  methods: {
    SeenNotification () {
      const id = this.$route.params.id
      if (this.$route.query.type === '0') {
        this.$http.get(`notifications/${id}`).then(() => {}).catch(() => {})
        this.$http.get(`notifications/show/${id}`).then(res => {
          this.NotifeData = res.data.params
        }).catch(err => {
          this.$vs.loading.close()
          console.log('errr', err)
        })
      }

      if (this.$route.query.type === '1') {
        this.$http.get(`showEssentialMessage/${id}`).then(res => {
          this.NotifeData = res.data.params
          // console.log('res es', res.data.params)
        }).catch(err => {
          console.log('errr', err)
        })
      }
    }
  },
  created () {
    this.SeenNotification()
  },
  mounted () {
    this.SeenNotification()
    this.$store.dispatch('notif/getNotificationList').then((res) => { console.log(res) }).catch((err) => { console.log(err) })
  }
}
</script>

<style scoped>
.blue-light {
  background-color: #BFDBFE !important;
  border-radius: 1rem;
  filter: drop-shadow(5px 5px 10px #BFDBFE);
}

.bg-card {
  box-shadow: none !important;
  background-color: #e9ebec !important;
}
.hover-item:hover {
  transition: 0.5s;
  background-color: #F3F4F6 !important;
}
.badgeAreaWaitAnswer {
  background-color: #fa9276;
  padding: 2px;
  color: white;
  border-radius: 2rem;
  font-size: 8px !important;
}
.badgeAreaAnswered {
  background-color: #6EE7B7;
  padding: 2px;
  color: white;
  border-radius: 2rem;
  font-size: 8px !important;
}
</style>
